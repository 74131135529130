//React imports
import React, { useEffect, useState } from "react";

//Own imports
import {
  AppHeaderComponent,
  AppButtonComponent,
  CampaignApi,
  App,
} from "@unity/components";
import Loading from "../../common/Loading";
import FilterTable from "../../common/FilterTable";
import NumberFullStats from "./NumberFullStats";
import NumberGetUserStats from "./NumberGetUserStats";
import ComponentTabs from "../../common/Tabs";
import MarketingReport from "./report/MarketingReport";

export default function Dashboard({ context, route, history }) {
  const [tabValue, setTabValue] = useState(0);

  const tabs = [
    { icon: "", label: "Stats" },
    { icon: "", label: "Report" },
  ];

  const handleChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="marketing-dashboard"
        subpage="Stats"
      />

      <ComponentTabs
        tabs={tabs}
        value={tabValue}
        setValue={setTabValue}
        handleChange={handleChange}
      />
      <div style={{ paddingTop: 160 }}>
        {tabValue === 0 && (
          <>
            <div style={{ paddingTop: 20 }}>
              <NumberFullStats />
            </div>
            <div style={{ paddingTop: 20 }}>
              <NumberGetUserStats />
            </div>
          </>
        )}
        {tabValue === 1 && <MarketingReport />}
      </div>
    </>
  );
}
