import React, { useState, useEffect } from "react";

import {
  AppHeaderComponent,
  CampaignApi,
  PersonnelApi,
} from "@unity/components";
import ApiLoader from "../common/ApiLoader";
import { useHistory } from "react-router-dom";
import CampaignDetailsForm from "../modules/campaign/forms/CampaignDetailsForm";
import CampaignObjectivesForm from "../modules/campaign/forms/CampaignObjectivesForm";
import CampaignPlanForm from "../modules/campaign/forms/CampaignPlanForm";
import CampaignRelatedProductsForm from "../modules/campaign/forms/CampaignRelatedProductsForm";
import CampaignCreateStep from "../modules/campaign/CampaignCreateStep";

export default function CampaignCreate({ route, context }) {
  const [data, setData] = useState({ related_products: [] });
  const [loading, setLoading] = useState({ status: false });
  const [activeStep, setActiveStep] = useState(0);

  const [tempData, setTempData] = useState({});
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [groups, setGroups] = useState([]);
  let history = useHistory();

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving, Please Wait...." });
    const userObj = users.find((e) => e.id === data["campaign_owner"]);
    data["campaign_owner_name"] = userObj.name;
    await CampaignApi.saveCampaign(data);

    setLoading({ status: true, data: "Save Success!, Please Wait...." });

    setTimeout(() => {
      setData({ related_products: [] });
      history.push("/campaigns/index");
    }, 2000);
  };

  const campaignSetup = async () => {
    const res = await CampaignApi.getCampaignCreateSetup();
    setTempData(res.data);
    setProducts(res.data.products);
    setGroups(res.data.groups);
    //setUsers(res.data.users);

    const res2 = await PersonnelApi.getPersonnel();
    console.log("Personnal", res2.data);
    setUsers(res2.data);
  };

  console.log(tempData);

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleUpload = (e) => {
    setData({ ...data, file_upload: e });
  };

  const onChange = (e) => {
    const newData = { ...data };
    if (e.target.name === "campaign_owner") {
      const userObj = users.find((t) => t.id === e.target.value);
      newData["campaign_owner_name"] = userObj.name;
    }
    newData[e.target.name] = e.target.value || e.target.checked;
    setData(newData);
  };

  useEffect(() => {
    campaignSetup();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={handleSave}
          context={context}
          theme={context.theme}
          name="campaigns"
          subpage="create"
        />
        <ApiLoader status={loading.status} data={loading.data} />
        <div style={{ paddingTop: 120, paddingBottom: 40 }}>
          <CampaignCreateStep
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleSave={handleSave}
          >
            {activeStep === 0 && (
              <CampaignDetailsForm
                onChange={onChange}
                data={data}
                users={users}
                groups={groups}
              />
            )}
            {activeStep === 1 && (
              <CampaignObjectivesForm onChange={onChange} data={data} />
            )}
            {activeStep === 2 && (
              <CampaignRelatedProductsForm
                onChange={onChange}
                data={data}
                setData={setData}
                products={products}
                type="create"
              />
            )}
            {activeStep === 3 && (
              <CampaignPlanForm
                upload={handleUpload}
                onChange={onChange}
                data={data}
              />
            )}
          </CampaignCreateStep>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
