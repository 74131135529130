import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  CampaignApi,
  ClientsApi,
  PersonnelApi,
  TargetsApi,
} from "@unity/components";
import Loading from "../../../common/Loading";
import ClientToLeadForm from "../forms/ClientToLeadForm";
import TargetToLeadForm from "../forms/TargetToLeadForm";

export default function ConvertLeadPopup(props) {
  const [data, setData] = useState({});
  const [client, setClient] = useState(false);
  const [users, setUsers] = useState([]);
  const [target, setTargetData] = useState(false);
  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const getData = async () => {
    if (props.data.customer_type === 0) {
      const res = await ClientsApi.getClientSingle(props.data.client_id); //get client_id props.data.client_id
      var contacts = [];
      res.data.client_site &&
        res.data.client_site.map((site, key) => {
          site.site_contact &&
            site.site_contact.map((contact, key) => {
              console.log("Site Contacts", contacts);
              contacts.push(contact);
            });
        });

      setClient({ client: res, contacts: contacts });
      setData({
        customer_name: res.data.client_name,
        customer_tel: res.data.client_tel,
      });
    } else if (props.data.customer_type === 1) {
      const res = await TargetsApi.getSingleTarget(props.data.client_id);
      setTargetData(res.data);
      console.log("Target res", res);
      setData({
        customer_id: res.data.id,
        customer_type: props.data.customer_type,
        lead_contact_name: res.data.contact_name,
        lead_contact_id: res.data.id,
        lead_contact_email: res.data.contact_email,
        contact_phone: res.data.contact_phone ? res.data.contact_phone : 0,
      });
      console.log("Api data", res);
    }

    //console.log("API DATA", res);

    //const userRes = await CampaignApi.getCampaignCreateSetup();
    const userRes = await PersonnelApi.getPersonnel();
    console.log("UserRes", userRes);

    setUsers(userRes.data ? userRes.data : []);
  };

  const handleAllocateChange = (e) => {
    var uuid = e.target.value;
    var userObj = users.find((e) => e.uuid === uuid);

    const newData = { ...data };
    newData["allocated_uuid"] = uuid;
    newData["allocated_name"] = userObj.name;

    setData(newData);
  };

  const handleSelectChange = (e) => {
    var id = e.target.value;
    var contactObj = client.contacts.find((e) => e.site_contact_id === id);
    const newData = { ...data };
    newData["lead_contact_name"] = contactObj.site_contact_name;
    newData["lead_contact_id"] = contactObj.site_contact_id;
    newData["lead_contact_email"] = contactObj.site_contact_email;
    newData["lead_customer_id"] = client.client.data.ref;
    setData(newData);
  };

  useEffect(() => {
    getData();
  }, []);

  if (props.data.customer_type === 0) {
    return (
      <>
        <ClientToLeadForm
          open={props.open}
          handleCancel={props.handleCancel}
          handleSelectChange={handleSelectChange}
          handleChange={handleChange}
          handleAllocateChange={handleAllocateChange}
          handleSave={props.handleSave}
          client={client}
          users={users}
          data={data}
        />
      </>
    );
  } else
    return (
      <>
        <TargetToLeadForm
          open={props.open}
          handleCancel={props.handleCancel}
          handleSelectChange={handleSelectChange}
          handleChange={handleChange}
          handleAllocateChange={handleAllocateChange}
          handleSave={props.handleSave}
          client={client}
          users={users}
          data={data}
          targetData={target}
          loading={props.loading}
        />
      </>
    );
}
