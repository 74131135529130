import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import CampaignIndex from "./pages/index";
import CampaignView from "./pages/view";
import CampaignCreate from "./pages/create";
import CampaignResponses from "./pages/campaign-responses/CampaignResponses";
import CampaignDetailsEdit from "./pages/edit";
import LeadsGeneratedIndex from "./pages/leads/LeadsGeneratedIndex";
import CampaignResponsesView from "./pages/campaign-responses/CampaignResponsesView";
import CampaignStatistics from "./pages/campaign-statistics/view";
import Dashboard from "./pages/campaign-statistics/Dashboard";
export default function Root(props) {
  let context = { ...props };
  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props,
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/index"
                          exact
                          component={(route) => (
                            <CampaignIndex
                              context={context}
                              route={route}
                              history={history}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/view/:id"
                          exact
                          component={(route) => (
                            <CampaignView
                              context={context}
                              route={route}
                              history={history}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/create"
                          exact
                          component={(route) => (
                            <CampaignCreate
                              context={context}
                              route={route}
                              history={history}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/responses/:id/index"
                          exact
                          component={(route) => (
                            <CampaignResponses
                              context={context}
                              route={route}
                              history={history}
                              type="edit"
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/edit/:id"
                          exact
                          component={(route) => (
                            <CampaignDetailsEdit
                              context={context}
                              route={route}
                              history={history}
                              type="edit"
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/leads/:id/index"
                          exact
                          component={(route) => (
                            <LeadsGeneratedIndex
                              context={context}
                              route={route}
                              history={history}
                              type="edit"
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/responses/:camp_id/view/:id"
                          exact
                          component={(route) => (
                            <CampaignResponsesView
                              context={context}
                              route={route}
                              history={history}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/campaigns/statistics/:id"
                          exact
                          component={(route) => (
                            <CampaignStatistics
                              context={context}
                              route={route}
                              history={history}
                            />
                          )}
                        />
                      )}

                    {props.auth.access.campaigns["A"] &&
                      props.auth.access.campaigns["R"] && (
                        <Route
                          path="/marketing-dashboard/index"
                          exact
                          component={(route) => (
                            <Dashboard
                              context={context}
                              history={history}
                              route={route}
                            />
                          )}
                        />
                      )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
