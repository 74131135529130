import AppButton from "../../common/AppButton";
import { Icon } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

export default function MarketingInterfaceCard({
  data,
  type,
  handleNavigation,
}) {
  return (
    <div className="col-lg-4" style={{ paddingTop: 20 }}>
      <div style={{ borderStyle: "groove", borderWidth: 5, height: 200 }}>
        <div style={{ textAlign: "center", fontSize: 20 }}>
          <p>{data.name}</p>
          <Icon>{data.icon}</Icon>
        </div>
        {data.createPath.edit && (
          <Link to={{ pathname: data.createPath.edit }}>
            <AppButton
              text="edit"
              style={{
                background: "#092f57",
                color: "white",
                justifyContent: "center",
                marginLeft: 15,
                width: "90%",
                marginTop: 10,
              }}
            />
          </Link>
        )}

        {data.addNew && (
          <Link
            to={{
              pathname: data.createPath.create,
              state: { tag: data.createPath.tag },
            }}
          >
            <AppButton
              text="create"
              btnIcon="add"
              style={{
                background: "green",
                color: "white",
                justifyContent: "center",
                marginLeft: 15,
                width: "90%",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Link>
        )}
      </div>
    </div>
  );
}
