import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  CampaignApi,
} from "@unity/components";

import Loading from "../../common/Loading";
import FilterTable from "../../common/FilterTable";

export default function NumberFullStats(props) {
  const [data, setData] = useState(false);

  const getData = async () => {
    const res = await CampaignApi.getFullStats();

    const newData = [res.data[0]];

    setData(newData);

    console.log("newData", newData);

    //setData(res.data[0].stats);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(data);

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].date;
        },
      },
    },
    {
      name: "blocks",
      label: "Blocks",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.blocks;
        },
      },
    },
    {
      name: "bounce_drops",
      label: "Bounce Drops",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.bounce_drops;
        },
      },
    },
    {
      name: "bounces",
      label: "Bounces",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.bounces;
        },
      },
    },
    {
      name: "unique_clicks",
      label: "Clicks",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.unique_clicks;
        },
      },
    },
    {
      name: "deferred",
      label: "Deferred",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.deferred;
        },
      },
    },
    {
      name: "delivered",
      label: "Delivered",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.delivered;
        },
      },
    },
    {
      name: "invalid_emails",
      label: "Invalid Emails",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.invalid_emails;
        },
      },
    },
    {
      name: "unique_opens",
      label: "Emails Opened",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.unique_opens;
        },
      },
    },
    {
      name: "processed",
      label: "Processed",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.processed;
        },
      },
    },
    {
      name: "requests",
      label: "Requests",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.requests;
        },
      },
    },
    {
      name: "spam_report_drops",
      label: "Spam Reports Dropped",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.spam_report_drops;
        },
      },
    },
    {
      name: "spam_reports",
      label: "Spam Reports",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.spam_reports;
        },
      },
    },
    {
      name: "unsubscribes",
      label: "Unsubscribes",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.unsubscribes;
        },
      },
    },
    {
      name: "unsubscribe_drops",
      label: "Unsubscribe Drops",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].stats[0].metrics.unsubscribe_drops;
        },
      },
    },
  ];

  const options = {};

  if (data) {
    return (
      <>
        <div>
          <FilterTable
            columns={columns}
            data={data}
            options={options}
            title={"Full Send Grid Stats"}
          />
        </div>
      </>
    );
  } else return <Loading />;
}