import React, { useState } from "react";
import AppButton from "../../../common/AppButton";

export default function ResponseCard({ data }) {
  const [open, setOpen] = useState(false);
  const [activityOpen, setActivityOpen] = useState(false);

  console.log(data);

  const handleActivityOpen = () => {
    setActivityOpen(true);
    window.open(
      `/activities/communication/campaign/${data.campaign_id}/responses/${
        data.id
      }/${6}/index`,
      "_blank"
    );
  };

  return (
    <div className="col-lg-12" style={{ paddingTop: 20 }}>
      <div style={{ borderStyle: "groove", borderWidth: 5, height: "auto" }}>
        <div style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}>
          <p>{data.subject}</p>
        </div>
        <div style={{ textAlign: "left", fontWeight: "bold" }}>
          Response Type: {data.type}
          Date: {data.created_at}
        </div>

        {open && (
          <div
            style={{ paddingTop: 20 }}
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        )}

        <div style={{ paddingTop: 20 }}>
          {!open && (
            <AppButton
              text="view"
              btnIcon="visibility"
              style={{
                background: "green",
                color: "white",
                justifyContent: "center",
                marginLeft: 15,
                width: "auto",
                marginTop: 10,
                marginBottom: 10,
              }}
              onClick={(e) => {
                setOpen(true);
              }}
            />
          )}
          {open && (
            <AppButton
              text="close"
              btnIcon="visibility"
              style={{
                background: "red",
                color: "white",
                justifyContent: "center",
                marginLeft: 15,
                width: "auto",
                marginTop: 10,
                marginBottom: 10,
              }}
              onClick={(e) => {
                setOpen(false);
              }}
            />
          )}

          <AppButton
            text="View Activities"
            btnIcon="visibility"
            style={{
              background: "green",
              color: "white",
              justifyContent: "center",
              marginLeft: 15,
              width: "auto",
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={handleActivityOpen}
          />
        </div>
      </div>
    </div>
  );
}
