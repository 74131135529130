import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

export default function CampaignDetailsForm(props) {
  return (
    <div>
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-4">
            <TextField
              label="Campaign Name"
              name="campaign_name"
              value={props.data.campaign_name}
              fullWidth={true}
              onChange={(e) => props.onChange(e)}
              disabled={props.type === "view"}
            />
          </div>

          <div className="form-group col-lg-4">
            {props.type === "view" ? (
              <TextField
                value={props.data.associated_bu_name}
                disabled
                fullWidth
                label="Associated BU"
              />
            ) : (
              <>
                <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                  Associated BU
                </InputLabel>
                <Select
                  fullWidth
                  name="associated_bu"
                  value={props.data.associated_bu}
                  onChange={(e) => props.onChange(e)}
                  disabled={props.type === "view"}
                >
                  {props.groups.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          </div>

          <div className="form-group col-lg-4">
            {props.type === "view" ? (
              <TextField
                value={props.data.campaign_owner_name}
                disabled
                fullWidth
                label="Campaign Owner"
              />
            ) : (
              <>
                <InputLabel style={{ margin: 0, fontSize: "1rem" }}>
                  Campaign Owner
                </InputLabel>
                <Select
                  fullWidth
                  name="campaign_owner"
                  value={props.data.campaign_owner}
                  onChange={(e) => props.onChange(e)}
                  disabled={props.type === "view"}
                >
                  {props.users.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-lg-4">
            <TextField
              label="Campaign Budget"
              name="campaign_budget"
              type="number"
              value={props.data.campaign_budget}
              fullWidth={true}
              disabled={props.type === "view"}
              onChange={(e) => props.onChange(e)}
            />
          </div>

          <div className="form-group col-lg-4">
            <TextField
              label="Anticipated Profit"
              name="anticipated_profit"
              type="number"
              value={props.data.anticipated_profit}
              fullWidth={true}
              onChange={(e) => props.onChange(e)}
              disabled={props.type === "view"}
            />
          </div>
          <div className="form-group col-lg-4">
            <FormControlLabel
              style={{ marginTop: 10 }}
              control={
                <Checkbox
                  checked={props.data.test_campaign}
                  onChange={(e) => props.onChange(e)}
                  name="test_campaign"
                  disabled={props.type === "view"}
                />
              }
              label="Test Campaign"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
