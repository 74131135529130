import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  CampaignApi,
} from "@unity/components";

import Loading from "../../common/Loading";

export default function NumberCompletedTasks(props) {
  console.log(props);

  const [data, setData] = useState(false);

  const getData = async () => {
    const res = await CampaignApi.getCompletedActivities({
      business: true,
      from: "",
      to: "",
      type: 2,
      campaign_id: props.campaign_id,
    });

    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(data);

  if (data) {
    return (
      <>
        <div className="report-counter" style={{ background: props.bg }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <p className="report-title" style={{ color: "white" }}>
                  Number of completed tasks
                </p>
              </div>
            </div>
            <p style={{ color: "white" }} className="count">
              {data.count}
            </p>
          </div>
        </div>
      </>
    );
  } else return <Loading />;
}
