import React from "react";
import TextField from "@material-ui/core/TextField";
import FileBase64 from "react-file-base64";

export default function CampaignPlanForm(props) {
  return (
    <div>
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Campaign Plan"
              name="campaign_plan"
              fullWidth={true}
              multiline
              value={props.data.campaign_plan}
              onChange={(e) => props.onChange(e)}
              disabled={props.type === "view"}
            />
          </div>
          <div className="form-group col-lg-12">
            <FileBase64 multiple={false} onDone={(e) => props.upload(e)} />
          </div>

          <div className="form-group col-lg-12">
            {props.document && (
              <a href={props.document.link}>Download Document</a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
