import React, { useContext, useEffect, useState } from "react";
//import { storeResponse } from "../../../../api/campaigns";
//import { saveLeads } from "../../../../api/leads";
import { AppHeaderComponent, CampaignApi, ClientsApi } from "@unity/components";
import AuthContext from "../../services/AuthContext";
import AppButton from "../../common/AppButton";
import FilterTable from "../../common/FilterTable";
import { Link, useHistory } from "react-router-dom";
//import ResponseViewPopup from "../../../common/ResponseViewPopup";
import ConvertLeadPopup from "../../modules/campaign/popups/ConvertLeadPopUp";
import NewResponsePopup from "../../modules/campaign/popups/NewResponsePopUp";
//import ResponseActivity from "./ResponseActivity";

export default function CampaignResponses(props) {
  const context = useContext(AuthContext);
  const [popup, setPopup] = useState({ state: false, data: false });
  const [active, setActive] = useState(false);
  const [newResponse, setNewResponse] = useState(false);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([{}]);
  const [loading, setLoading] = useState(false);

  const handleNewResponseCancel = () => {
    setNewResponse(false);
  };
  const handleNewResponseAccept = async (data) => {
    data["campaign_id"] = props.route.match.params.id;
    await CampaignApi.saveResponse(data);
    setNewResponse(false);
  };

  const handleCancel = () => {
    setPopup({ state: false, data: false });
  };

  const handleAccept = async (data) => {
    setLoading(true);
    data["campaign_id"] = props.route.match.params.id;
    const save = await CampaignApi.saveLead(data);
    console.log("SAVED OBJECT", data);
    console.log("SAVEING RES", save);
    setLoading(false);
    setPopup({ state: false, data: false });
  };

  console.log("Index data", data);

  const getData = async () => {
    const res = await CampaignApi.getResponsesIndexByCampaign(
      props.route.match.params.id
    );

    setData(res.data);

    /*  const res2 = await ClientsApi.getClients();
    setClients(res2.data); */
  };

  useEffect(() => {
    getData();
  }, []);

  const select = "multiple";

  const options = {
    selectableRows: select,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {
        const res = await CampaignApi.deleteResponse(data[item.dataIndex].id);
        console.log(res);
      });
    },
  };
  const columns = [
    {
      name: "client_name",
      label: "Customer",
    },
    { name: "subject", label: "Subject" },
    { name: "type", label: "Type" },
    { name: "created_at", label: "Created At" },
    {
      name: "",
      label: "View Response",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Link
                to={{
                  pathname: `/campaigns/responses/${props.route.match.params.id}/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>

              <a
                onClick={() =>
                  setPopup({
                    state: true,
                    data: data[dataIndex],
                  })
                }
              >
                <AppButton
                  text="convert to lead"
                  btnIcon="check"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                    marginLeft: 10,
                  }}
                />
              </a>
            </>
          );
        },
      },
    },
  ];

  return (
    <div className="container-fluid">
      {popup.state && (
        <ConvertLeadPopup
          open={popup.state}
          data={popup.data}
          handleCancel={handleCancel}
          handleSave={handleAccept}
          loading={loading}
        />
      )}

      <NewResponsePopup
        handleAccept={handleNewResponseAccept}
        handleCancel={handleNewResponseCancel}
        open={newResponse}
        campaign_id={props.route.match.params.id}
      />
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="campaigns"
        subpage="Marketing Leads"
      />
      {!active ? (
        <>
          {props.type === "edit" && (
            <div
              style={{ position: "absolute", left: 50, top: 160, zIndex: 999 }}
            >
              <a onClick={() => setNewResponse(true)}>
                <AppButton
                  text="Add Custom Response"
                  btnIcon="add"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </a>
            </div>
          )}

          <div style={{ paddingTop: 140 }}>
            {<FilterTable columns={columns} data={data} options={options} />}
          </div>
        </>
      ) : (
        <ResponseActivity
          active={active}
          setActive={setActive}
          type={props.type}
        />
      )}
    </div>
  );
}
