import React from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  CampaignApi,
} from "@unity/components";
import NumberCompletedTasks from "./NumberCompletedTasks";
import NumberLeadsGenerated from "./NumberLeadsGenerated";
import NumberResponses from "./NumberResponses";
import NumberCampaignStats from "./NumberCampaignStats";
import NumberEmailOneOnOne from "./NumberEmailOneOnOne";
import LegacyPeopleContacted from "./LegacyNumberPeopleContacted";

export default function CampaignStatistics({ route, history, context }) {
  return (
    <>
      <AppHeaderComponent
        context={context}
        theme={context.theme}
        name="campaigns"
        subpage="Stats"
      />

      <div style={{ paddingTop: 120 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, marginRight: 5 }}>
            <NumberCompletedTasks
              bg="rgb(99 149 249)"
              campaign_id={route.match.params.id}
            />
          </div>
          <div style={{ flex: 1, marginRight: 5 }}>
            <NumberLeadsGenerated
              bg="rgb(99 149 249)"
              campaign_id={route.match.params.id}
            />
          </div>
          <div style={{ flex: 1, marginRight: 5 }}>
            <NumberResponses
              bg="rgb(99 149 249)"
              campaign_id={route.match.params.id}
            />
          </div>
          <div style={{ flex: 1, marginRight: 5 }}>
            <LegacyPeopleContacted
              bg="rgb(99 149 249)"
              campaign_id={route.match.params.id}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
          <NumberCampaignStats
            bg="rgb(99 149 249)"
            campaign_id={route.match.params.id}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 10 }}>
          <NumberEmailOneOnOne
            bg="rgb(99 149 249)"
            campaign_id={route.match.params.id}
          />
        </div>
      </div>
    </>
  );
}
