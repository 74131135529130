import React, { useContext } from "react";
import TextField from "@material-ui/core/TextField";
import AppButton from "../../../common/AppButton";
import AuthContext from "../../../services/AuthContext";
import { MenuItem, Select } from "@material-ui/core";

export default function CampaignRelatedProductsForm(props) {
  const context = useContext(AuthContext);

  const handleAdd = () => {
    const newData = { ...props.data };
    newData.related_products.push({});
    props.setData(newData);
  };

  console.log(props);

  const handleChange = (e, key) => {
    const newData = { ...props.data };
    newData.related_products[key][e.target.name] = e.target.value;
    props.setData(newData);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="form-row">
          {props.data.related_products.map((item, key) => {
            return (
              <div key={key} className="form-group col-lg-12">
                {props.type === "create" && (
                  <TextField
                    label="Related Product / Service"
                    name="description"
                    fullWidth={true}
                    value={item.description}
                    multiline
                    onChange={(e) => handleChange(e, key)}
                    disabled={props.type === "view"}
                  />
                )}
                {props.type === "edit" && (
                  <TextField
                    label="Related Product / Service"
                    name="description"
                    fullWidth={true}
                    value={item.description}
                    multiline
                    onChange={(e) => props.onChange(e, "related_products", key)}
                    disabled={props.type === "view"}
                  />
                )}
                {/* for when product system is live */}
                {/*                   <Select>
                  {props.products.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  }
                </Select>  */}
              </div>
            );
          })}
          <div className="form-group col-lg-12">
            {props.type !== "view" && (
              <AppButton
                onClick={() => handleAdd()}
                text="Add New"
                btnIcon="add"
                style={{
                  background: context.theme.sidebar.background,
                  color: "white",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
