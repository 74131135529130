import React from "react";

import DataTable from "../../../common/DataTable";

export default function NumberOfLeadsGen({ data }) {
  const columns = [
    { field: "campaign_name", headerName: "Campaign", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
  ];

  return (
    <>
      <DataTable columns={columns} rows={data} height={300} />
    </>
  );
}
