import React, { useEffect, useState } from "react";

import { Button, InputLabel, CircularProgress } from "@mui/material";
import { TextField, MenuItem, Select } from "@material-ui/core";

import {
  AppHeaderComponent,
  CampaignApi,
  PersonnelApi,
} from "@unity/components";
import NumberOfLeadsGen from "./NumberOfLeadsGen";
import NumberOfSalesGen from "./NumberOfSalesGen";
import NumberOfTouches from "./NumberOfTouches";

export default function MarketingReport() {
  const [dateData, setDateData] = useState({
    startDate: null,
    endDate: null,
    person: null,
  });
  const [data, setData] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    const personnel = await PersonnelApi.getPersonnel();
    setPersonnel(personnel.data);
  };

  const handleSearch = async () => {
    setLoading(true);
    const res = await CampaignApi.getMarketingLeads(
      dateData.person.uuid,
      dateData.startDate,
      dateData.endDate
    );
    console.log(res);

    const salesRes = await CampaignApi.getSalesLeads(
      dateData.person.uuid,
      dateData.startDate,
      dateData.endDate
    );

    const numberOfTouches = await CampaignApi.getNumberofTouchesVerbose(
      dateData.person.id,
      dateData.startDate,
      dateData.endDate
    );

    setData({
      marketingLeads: res.data,
      salesLeads: salesRes.data,
      touches: numberOfTouches,
    });

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div className="form-row">
            <div className="form-group col-lg-6">
              <InputLabel>Start Date</InputLabel>
              <TextField
                name="startDate"
                type="datetime-local"
                value={dateData.startDate}
                onChange={(e) =>
                  setDateData({
                    ...dateData,
                    [e.target.name]: e.target.value,
                  })
                }
                fullWidth
              />
            </div>
            <div className="form-group col-lg-6">
              <InputLabel>End Date</InputLabel>
              <TextField
                name="endDate"
                type="datetime-local"
                value={dateData.endDate}
                onChange={(e) =>
                  setDateData({
                    ...dateData,
                    [e.target.name]: e.target.value,
                  })
                }
                fullWidth
              />
            </div>
            <div className="form-group col-lg-6">
              <InputLabel>Select Personnal</InputLabel>
              <Select
                value={dateData.person ? dateData.person : ""}
                name="person"
                onChange={(e) =>
                  setDateData({
                    ...dateData,
                    [e.target.name]: e.target.value,
                  })
                }
                fullWidth
              >
                {personnel &&
                  personnel.map((person, key) => {
                    return (
                      <MenuItem key={key} value={person}>
                        {person.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <div className="form-group col-lg-6" style={{ paddingTop: "25px" }}>
              {!loading ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSearch();
                  }}
                  fullWidth
                  disabled={loading}
                >
                  Search
                </Button>
              ) : (
                <CircularProgress />
              )}
            </div>
          </div>
        </div>
      </div>
      {data && (
        <>
          <NumberOfTouches data={data.touches} />
          <h3 style={{ paddingTop: "20px" }}>Number of Marketing Leads Gen</h3>
          <NumberOfLeadsGen data={data.marketingLeads} />
          <h3 style={{ paddingTop: "20px" }}>Number of Sale Leads Gen</h3>
          <NumberOfSalesGen data={data.salesLeads} />
        </>
      )}
    </>
  );
}
