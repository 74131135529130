import React, { useEffect, useState } from "react";
import {
  AppHeaderComponent,
  AppButtonComponent,
  CampaignApi,
} from "@unity/components";

import Loading from "../../common/Loading";

export default function NumberEmailOneOnOne(props) {
  const [data, setData] = useState(false);

  const getData = async () => {
    const res = await CampaignApi.getCampaignEmailOneOnOneReport(
      props.campaign_id,
    );

    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <div className="report-counter" style={{ background: props.bg }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <p className="report-title" style={{ color: "white" }}>
                  Number of One to One Emails
                </p>
              </div>
            </div>
            <p style={{ color: "white" }} className="count">
              {data}
            </p>
          </div>
        </div>
      </>
    );
  } else return <Loading />;
}
