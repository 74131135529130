import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";

import { CampaignApi, DynamicListApi, ClientsApi } from "@unity/components";

import { Select, MenuItem, TextField, InputLabel } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomerResponsePopUp({
  open,
  setOpen,
  campaignId,
  datas,
  route,
}) {
  const [data, setData] = useState({});
  const [single, setSingle] = useState({});

  let history = useHistory();

  console.log("data being passed in", datas);
  console.log(route);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = async () => {
    const newData = { ...data };

    newData["campaign_id"] = campaignId;
    newData["response_parent_id"] = route.match.params.id;

    newData["client_id"] = datas.client_id;
    newData["customer_type"] = datas.customer_type;

    setData(newData);

    const res = await CampaignApi.saveResponse(newData);
    console.log("SAVED DATA", res);

    location.reload();
  };

  console.log(data);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Log Customer Response
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Fill out details from the customer response
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            <InputLabel style={{ marginTop: 12 }}>Response Type?</InputLabel>
            <TextField
              fullWidth
              name="type"
              label="Type"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
            />
            <TextField
              fullWidth
              name="subject"
              label="Subject"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
            />
            <TextField
              fullWidth
              name="body"
              label="Description"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
              multiline
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleAccept()} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
