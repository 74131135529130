import React from "react";

import DataTable from "../../../common/DataTable";

export default function NumberOfSalesGen({ data }) {
  const columns = [
    { field: "name", headerName: "Company Name", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
  ];

  return (
    <>
      <DataTable columns={columns} rows={data} height={300} />
    </>
  );
}
