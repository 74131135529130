import React, { useEffect, useState } from "react";
import Loading from "../common/Loading";
import {
  AppHeaderComponent,
  CampaignApi,
  AppButtonComponent,
} from "@unity/components";
import MarketingInterface from "../modules/marketing/MarketingInterface";

export default function CampaignView({ context, route }) {
  const [data, setData] = useState({});

  const handleUpload = (e) => {};

  const handleChange = (name, value) => {};

  const id = route.match.params.id;

  //const routerState = route.location.state;

  console.log(route);
  const getData = async () => {
    const res = await CampaignApi.getCampaignSingle(route.match.params.id);
    setData(res.data);
  };

  console.log(data);

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="campaigns"
          subpage="view"
        />
        <div style={{ paddingTop: 120 }}>
          <MarketingInterface id={id} data={data} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
