import React, { useEffect, useState } from "react";
import AppButton from "../../common/AppButton";
import FilterTable from "../../common/FilterTable";
import Loading from "../../common/Loading";

import { AppHeaderComponent, CampaignApi } from "@unity/components";

export default function LeadsGeneratedIndex({ context, route, history }) {
  const [data, setData] = useState(false);

  const columns = [
    { name: "customer_name", label: "Customer Name" },
    { name: "customer_tel", label: "Customer Telephone" },
    { name: "lead_contact_name", label: "Lead Contact" },
    { name: "lead_contact_email", label: "Lead Contact Email" },
    { name: "lead_contact_source", label: "Lead Source" },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {});
    },
  };

  const getData = async () => {
    const res = await CampaignApi.getLeadsByCampaign(route.match.params.id);
    setData(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="campaigns"
          subpage="Sale Leads"
        />
        <div style={{ paddingTop: 120 }}>
          <FilterTable columns={columns} options={options} data={data} />
        </div>
      </>
    );
  } else return <Loading />;
}
