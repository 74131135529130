import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  CampaignApi,
  ClientsApi,
  PersonnelApi,
  TargetsApi,
} from "@unity/components";
import Loading from "../../../common/Loading";

export default function ClientToLeadForm({
  open,
  handleCancel,
  handleSelectChange,
  handleChange,
  handleAllocateChange,
  handleSave,
  client,
  users,
  data,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleCancel()}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <>
            {client && client.contacts.length > 0 && (
              <>
                <InputLabel>Choose Contact</InputLabel>
                <Select fullWidth onChange={(e) => handleSelectChange(e)}>
                  {client.contacts.map((contact, key) => {
                    return (
                      <MenuItem value={contact.site_contact_id} key={key}>
                        {contact.site_contact_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
            {users && users.length > 0 ? (
              <>
                <InputLabel>Allocate To</InputLabel>
                <Select fullWidth onChange={(e) => handleAllocateChange(e)}>
                  {users.map((user, key) => {
                    return (
                      <MenuItem value={user.uuid} key={key}>
                        {user.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : (
              <Loading />
            )}

            <TextField
              margin="dense"
              name="lead_contact_source"
              label="Lead Contact Source"
              multiline
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              fullWidth
            />
            <TextField
              margin="dense"
              name="notes"
              label="Notes"
              multiline
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              fullWidth
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="secondary">
            Close
          </Button>
          <Button onClick={() => handleSave(data)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
