import React, { useState, useEffect, useContext } from "react";
import FilterTable from "../common/FilterTable";
//import PageHeader from "../common/PageHeader";
import { Link } from "react-router-dom";
import Loading from "../common/Loading";
import AppButton from "../common/AppButton";

import {
  AppHeaderComponent,
  AppButtonComponent,
  CampaignApi,
} from "@unity/components";

export default function CampaignIndex({ context, route, history }) {
  const [data, setData] = useState(false);
  const [backup, setBackup] = useState(false);
  const [open, setOpen] = useState({ state: false });

  const handleAccept = async (records) => {
    const newData = [...data];
    records.map(async (item) => {
      await deleteCampaigns(item.id);
      newData.splice(item.data.dataIndex, 1);
    });
    setData(newData);
    setOpen({ state: false });
  };

  const handleClose = () => {
    setOpen({ state: false });
  };

  const checkBackup = () => {
    var data = localStorage.getItem("cForm");
    setBackup(JSON.parse(data));
  };

  const getCampaignIndex = async () => {
    const data = await CampaignApi.getCampaign();
    setData(data.data);
  };

  useEffect(() => {
    getCampaignIndex();
    //checkBackup();
  }, []);

  const columns = [
    { name: "id", label: "Reference" },
    { name: "campaign_name", label: "Name" },
    { name: "campaign_budget", label: "Campaign Budget" },
    { name: "campaign_owner_name", label: "Campaign Created By" },
    { name: "test", label: "Test Campaign" },
    {
      name: "",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].test === 1) {
            return <p>Test</p>;
          } else {
            return <p>Live</p>;
          }
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <Link
                to={{
                  pathname: `/campaigns/view/${data[dataIndex].id}`,
                  state: {
                    client: data[dataIndex].id,
                    campaign_name: data[dataIndex].campaign_name,
                    campaign_owner_name: data[dataIndex].campaign_owner_name,
                  },
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      var records = [];

      rowsDeleted.data.map(async (item, key) => {
        records.push({
          id: data[item.dataIndex].id,
          name: data[item.dataIndex].name,
          data: item,
        });
      });
      setOpen({
        state: true,
        title: "Are you sure you want to delete?",
        description:
          "Are you sure you want to delete the following records, these records will no longer be available across your software suite",
        data: records,
      });
      // rowsDeleted.data.map(async (item, key) => {
      //   const res = await deleteClient(data[item.dataIndex].id);
      // });
    },
  };

  if (data) {
    return (
      <>
        <AppHeaderComponent
          name="campaigns"
          context={context}
          theme={context.theme}
          addNew={() => (
            <Link to={"/campaigns/create"}>
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
        />
        <div style={{ paddingTop: 150 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
