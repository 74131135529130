import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';

export default function NumberOfTouches({ data }) {

  const touches = data.data;
  const emails = [];
  const other = [];

  for( const type in touches.email )
  {
    let count = touches.email[type];
    emails.push( { "name": type, "count": count } );
  }

  for( const model in touches.other )
  {
    let friendlyName = model.split("\\")[2];
    let count = touches.other[model];

    other.push( { "name": friendlyName, "count": count } );
  }

  return (
    <>
      <div
        className="report-counter col-lg-12"
        style={{ background: "blue", marginTop: "20px" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <p className="report-title" style={{ color: "white" }}>
                Bulk Phone Calls:
              </p>
            </div>
          </div>
          <p style={{ color: "white" }} className="count">
            {touches.bulkPhone}
          </p>
        </div>
      </div>

      <br/>

      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <h4>Emails:</h4>
        </Grid>
          {(emails && emails.length > 0) ?
            emails.map((value, key) => {
              return (
              <Grid item key={key} xs={4} style={{ textAlign: "center" }}>
                <Paper style={{ padding: "5px" }}>
                  <h5>{value.name}</h5>
                  <p>{value.count}</p>
                </Paper>
              </Grid>
              )
            }) : () => {
              return (
                <Grid item xs={12}>
                  <Paper>
                    <p>No Email Stats Found</p>
                  </Paper>
                </Grid>
              )
            }
          }
      </Grid>

      <br/>

      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <h4>Other:</h4>
        </Grid>
          {(other && other.length > 0) ?
            other.map((value, key) => {
              return (
              <Grid item key={key} xs={4} style={{ textAlign: "center" }}>
                <Paper style={{ padding: "5px" }}>
                  <h5>{value.name}</h5>
                  <p>{value.count}</p>
                </Paper>
              </Grid>
              )
            }) : () => {
              return (
                <Grid item xs={12}>
                  <Paper>
                    <p>No Misc. Stats Found</p>
                  </Paper>
                </Grid>
              )
            }
          }
      </Grid>
    </>
  );
}
