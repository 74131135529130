import MarketingInterfaceCard from "./MarketingInterfaceCard";
import React from "react";

export default function MarketingInterface(props) {
  const campaign_id = props.id;

  const steps = [
    {
      id: 1,
      name: "Campaign Details",
      icon: "settings_applications_icon",
      createPath: {
        edit: `/campaigns/edit/${campaign_id}`,
      },
      addNew: false,
    },
    {
      id: 2,
      name: "Campaign Activites",
      icon: "rowing_icon",
      createPath: {
        create: `/activities/communication/${campaign_id}/create`,
        edit: `/activities/communication/campaign/${campaign_id}/all/index`,
        tag: "Campaign",
      },
      addNew: true,
    },
    {
      id: 3,
      name: "Marketing Leads",
      icon: "assignment_icon",
      createPath: {
        create: `/campaigns/responses/${campaign_id}/create`,
        edit: `/campaigns/responses/${campaign_id}/index`,
      },
      addNew: false,
    },
    {
      id: 4,
      name: "Campaign Statistics",
      icon: "format_list_numbered_icon",
      createPath: {
        edit: `/campaigns/statistics/${campaign_id}`,
      },
      addNew: false,
    },
    {
      id: 5,
      name: "Sale Leads",
      icon: "attach_money_icon",
      createPath: {
        create: "/campaigns/leads/create",
        edit: `/campaigns/leads/${campaign_id}/index`,
      },
      addNew: false,
    },
    {
      id: 6,
      name: "Target List Generator",
      icon: "track_changes",
      createPath: {
        create: "/list-creator/create",
        edit: `/list-creator/index/${campaign_id}`,
      },
      addNew: true,
    },
  ];

  const handleNavigation = (id) => {
    if (id === 2) {
    }
  };

  return (
    <>
      <div className="container">
        <div classname="col-lg-12">
          <div className="block">
            <div
              className="form-row"
              style={{ alignItems: "center", display: "flex" }}
            >
              <div className="col-lg-12" style={{ textAlign: "center" }}>
                <div>
                  <p style={{ fontSize: "30px", fontWeight: "bold" }}>
                    {props.data.campaign_name}
                  </p>
                </div>
              </div>
              <div className="col-lg-4" style={{ textAlign: "center" }}>
                <p>{props.data.campaign_owner_name}</p>
                <p>Budget: 100</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {steps.map((item, key) => {
            return <MarketingInterfaceCard data={item} key={key} />;
          })}
        </div>
      </div>
    </>
  );
}
