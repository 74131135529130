import React from "react";
import TextField from "@material-ui/core/TextField";

export default function CampaignObjectivesForm(props) {
  return (
    <div>
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <TextField
              label="Campaign Objectives"
              name="campaign_objectives"
              fullWidth={true}
              multiline
              value={props.data.campaign_objectives}
              onChange={(e) => props.onChange(e)}
              disabled={props.type === "view"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
