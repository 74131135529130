import React, { useEffect } from "react";
import { AppHeaderComponent, CampaignApi, ClientsApi } from "@unity/components";
import { useState } from "react";
import Loading from "../../common/Loading";
import ResponseCard from "../../modules/campaign/responses/ResponseCard";
import AppButton from "../../common/AppButton";
import CustomerResponsePopUp from "../../modules/campaign/popups/CustomerResponsePopUp";

import { Link, useHistory } from "react-router-dom";
export default function CampaignResponsesView({ context, route, history }) {
  const [data, setData] = useState(false);
  const [parentData, setParentData] = useState([]);
  const [customerResponseOpen, setCustomerResponseOpen] = useState(false);

  console.log(route);

  const campaignId = route.match.params.camp_id;

  const handlePopUp = () => {
    setCustomerResponseOpen(true);
  };

  const handlePopUpClose = () => {
    setCustomerResponseOpen(false);
  };

  console.log("Route Data", route);

  const getData = async () => {
    const res = await CampaignApi.getResponseSingle(route.match.params.id);
    setData(res.data);

    const res2 = await CampaignApi.getResponseByParent(route.match.params.id);

    setParentData(res2.data);
  };

  console.log("Data", data);

  useEffect(() => {
    getData();
  }, []);

  if (data) {
    return (
      <>
        <CustomerResponsePopUp
          open={customerResponseOpen}
          setOpen={setCustomerResponseOpen}
          campaignId={campaignId}
          datas={data}
          route={route}
        />
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="campaigns"
          subpage="responses-view"
        />

        <div style={{ paddingTop: 120 }}>
          <AppButton
            text="Log Response From Customer"
            btnIcon="create"
            style={{
              background: "green",
              color: "white",
              justifyContent: "center",
              marginLeft: 15,
              width: "auto",
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={(e) => {
              setCustomerResponseOpen(true);
            }}
          />
          <Link
            to={{
              pathname: `/activities/communication/${route.match.params.camp_id}/create`,
              state: { tag: "Campaign" },
            }}
          >
            <AppButton
              text="Log Response from you"
              btnIcon="create"
              style={{
                background: "green",
                color: "white",
                justifyContent: "center",
                marginLeft: 15,
                width: "auto",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Link>
          <ResponseCard data={data} />
          {parentData.map((e) => (
            <ResponseCard data={e} />
          ))}
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
