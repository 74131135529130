import React, { useEffect, useState } from "react";
import { InputLabel, Select } from "@material-ui/core";
//import { getListItem, getListsCampaignIndex } from "../../../../api/campaigns";
import { DynamicListApi, ClientsApi, TargetsApi } from "@unity/components";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Checkbox,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import XSLoader from "../../../common/XSLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewResponsePopup(props) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [lists, setLists] = useState(false);
  const [listItems, setListItems] = useState(false);
  const [endPoint, setEndPoint] = useState("targets");
  const [searchData, setSearchData] = useState(false);

  const getLists = async () => {
    setLoading(true);
    const res = await DynamicListApi.getDynamicListIndex(); //TODO needs to be able to get an index of campaign responses
    setLists(res.data);
    setLoading(false);
  };

  const handleListChoice = async (id) => {
    //Get the id from the list selected then search for it, then grab the criteria
    const res = await DynamicListApi.getDynamicListSingle(id);

    setSearchData(res.data.criteria);
    //console.log(lists[id].criteria);
  };

  const handleEndPointChange = async (value) => {
    setEndPoint(value);

    const cri = { criteria: { ...searchData }, search: "" };

    if (value === "clients") {
      const res = await ClientsApi.fuzzyFilterSearch(value, cri);
      setListItems(res.data);
      setData({ ...data, ["customer_type"]: 0 });
    } else if (value === "targets") {
      const res = await TargetsApi.filterSetSearchTargets(cri);
      setData({ ...data, ["customer_type"]: 1 });
      setListItems(res.data);
    }
  };

  const handleListItemSelect = async (id) => {
    console.log(id);
    let searchId;

    //endPoint === "targets" ? (searchId = id) : (searchId = "");

    const contactObj =
      endPoint === "targets"
        ? listItems.find((e) => e.id === id.id)
        : listItems.find((e) => e.customer_id === id.customer_id);

    const newData = { ...data };

    if (endPoint === "clients") {
      newData["client_id"] = contactObj.customer_id;
    } else if (endPoint === "targets") {
      newData["client_id"] = contactObj.id;
    }

    //Added custom type for the index
    newData["type"] = "custom";

    setData(newData);
  };

  const handleTypedValue = async (value) => {
    console.log("BEING CALLED");

    const cri = { criteria: { ...searchData }, search: value };

    if (endPoint === "clients") {
      const res = await ClientsApi.fuzzyFilterSearch("clients", cri);
      setListItems(res.data);
    } else if (endPoint === "targets") {
      const res = await TargetsApi.filterSetSearchTargets(cri);
      setListItems(res.data);
    }
  };

  useEffect(() => {
    getLists();
  }, []);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Create New Custom Response
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Create a new custom response, this should be used when a response is
            generated from a custom activity
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            {lists ? (
              <>
                <InputLabel style={{ marginTop: 12 }}>
                  Choose Campaign Target List
                </InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => handleListChoice(e.target.value)}
                >
                  {lists.map((item, key) => {
                    return (
                      <MenuItem key={key} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : (
              <div>
                <XSLoader color={"red"} />
              </div>
            )}
            {lists && (
              <>
                <InputLabel style={{ marginTop: 12 }}>
                  Targets or Clients?
                </InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => handleEndPointChange(e.target.value)}
                >
                  <MenuItem value="clients">Clients</MenuItem>
                  <MenuItem value="targets">Targets</MenuItem>
                </Select>
              </>
            )}
            {listItems && (
              <>
                <Autocomplete
                  value={data.customer_id}
                  options={listItems}
                  //name={"customer_id"}
                  getOptionLabel={(item) =>
                    item.customer_name || item.company_name
                  }
                  onChange={(e, val) => handleListItemSelect(val)}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose From List" />
                  )}
                  onInputChange={(e, value) => handleTypedValue(value)}
                />
              </>
            )}
            <InputLabel style={{ paddingTop: 15 }}>
              What caused the response ?
            </InputLabel>
            <TextField
              fullWidth
              name="subject"
              label="Title"
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
            />
            <TextField
              fullWidth
              name="body"
              multiline
              label="Description"
              style={{ marginTop: 10 }}
              onChange={(e) =>
                setData({ ...data, [e.target.name]: e.target.value })
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleCancel()} color="secondary">
            Close
          </Button>
          <Button onClick={() => props.handleAccept(data)} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
